import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Heading } from "../components/Hero"

const AwardsAndMedia = ({ data, pageContext }) => {
  const { metaTitle, metaDescription, heading, blurb, awards } =
    data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  return (
    <Layout className="awards-media" navSpacer language={language}>
      <SEO title={metaTitle} description={metaDescription} lang={language} />
      <Heading heading={heading} blurb={blurb} sideColumnsSize={3} />

      <Section>
        <Columns sideColumnsSize={3}>
          <div class="awards">
            {awards.map(award => (
              <div className="awards__card" key={award.blurb}>
                <Image publicId={award.image} />
                <Text as="h2" text={award.heading} />
                <Text text={award.blurb} />
                {award.link && (
                  <a
                    className="standard-button contained mx-auto mt-1"
                    href={award.link}
                    title="View award page"
                    target="_blank"
                    rel="noreferrer">
                    View More
                  </a>
                )}
              </div>
            ))}
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const AwardsAndMediaPageQuery = graphql`
  query AwardsAndMediaQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        metaDescription
        metaTitle
        awards {
          heading
          blurb
          link
          image
        }
      }
    }
  }
`

export default AwardsAndMedia
