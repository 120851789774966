import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"
import ProcedureSelector from "../components/ProcedureSelector2"

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext
  let services = post.services
  let schemaData

  if (language === "en") {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: metaTitle,
      description: metaDescription,
      path: location.pathname,
      productontologyRelations: post.productontologyRelations
    }
  }

  return (
    <Layout language={language} navSpacer className="procedure-query">
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaData={schemaData}
        pathname={location.pathname}
        lang={language}
      />

      <ProcedureSelector
        heading={"Our Services & Procedures"}
        blurb={
          "Please select your gender and desired procedures to learn more. "
        }
        services={services}
      />

      {/** Fill out questionnaire and gallery section */}
      <Section
        zeroTopMobile
        zeroBottomMobile
        className="procedure-query__questionnaire-container relative">
        <Columns sideColumnsSize={1}>
          <div className="column procedure-query__questionnaire">
            <div className="procedure-query__questionnaire-image">
              <Image publicId="77P/DEV/77-self-evaluation" />
            </div>
            <div className="procedure-query__questionnaire-content">
              <Text
                as="h3"
                text="We would love to help you."
                className="procedure-query__questionnaire-heading"
              />
              <Text
                className="procedure-query__questionnaire-text"
                text="Please fill out our brief questionnaire to determine if any of our procedures are right for you."
              />
              <Button
                buttonText="FILL OUT QUESTIONNAIRE"
                href="https://consultation.77plasticsurgery.com/procedures"
                className="procedure-query__questionnaire-button"
                destination="external"
              />
            </div>
          </div>
        </Columns>
        <Section
          zeroTop
          zeroBottomMobile
          className="procedure-query__gallery-container">
          <Columns sideColumnsSize={2}>
            <div className="column">
              <div className="home__gallery">
                <div className="home__gallery--content procedure-query__gallery-content">
                  <h4 className="bold--500 mb-2 px-0">VIEW THE GALLERIES</h4>
                  <h3 className="px-0">
                    See the beautiful and natural results we have achieved for
                    thousands of patients.
                  </h3>
                  <Button
                    contained
                    buttonText="See our before & Afters"
                    href="/gallery/"
                    destination="internal"
                  />
                </div>

                <div className="home__gallery--images">
                  <Image publicId="77P/DEV/view-our-gallery-men" />
                  <Image publicId="77P/DEV/view-our-gallery-women" />
                </div>
              </div>
            </div>
          </Columns>
        </Section>
      </Section>

      {/** About us section */}
      <Section colorBack colorBackSecondary className="home__cta relative">
        <Image
          className="home__cta--logo mb-2--mobile"
          useAR={false}
          publicId="77P/DEV/77p_logo_gold"
          data-aos-duration="1200"
          data-aos="fade-right"
        />
        <Columns sideColumnsSize={2}>
          <div className="column is-one-third"></div>
          <div className="column is-2"></div>
          <div className="column" data-aos-duration="1200" data-aos="fade-left">
            <h3>Experience The Fan Method™</h3>
            <p className="mt-2 mb-3" role="heading">
              {post.learnMore.blurb}
            </p>
            <div className="button-group px-40--mobile">
              {post.learnMore.buttons.map(({ button }) => (
                <Button
                  key={button.href}
                  href={button.href}
                  buttonText={button.buttonText}
                  destination={button.destination}
                  appearance="white"
                />
              ))}
            </div>
          </div>
        </Columns>
      </Section>

      {/** Download ebook section */}
      <Section zeroTop zeroBottom colorBackSecondary>
        <Columns sideColumnsSize={3} className="procedure-query__ebook-columns">
          <div className="column procedure-query__ebook">
            <div className="procedure-query__ebook-img-container">
              <Image
                publicId="77P/DEV/book-stack.png"
                wrapperClassName="procedure-query__ebook-image"
              />
            </div>
            <div>
              <Text text="Before you make any decisions about cosmetic surgery, arm yourself with the knowledge to choose wisely." />
              <Button
                contained
                buttonText="DOWNLOAD PDF"
                href="/lp-14-questions-ask-choosing-plastic-surgeon-ebook/"
                destination="internal"
              />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query proceduresQueryPageEs($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        categories {
          label
          key
        }
        additionalProceduresHeading
        productontologyRelations
        schemas {
          presets
          custom
        }
        services {
          men {
            category
            groups {
              name
              pages {
                href
                name
              }
            }
          }
          women {
            category
            groups {
              name
              pages {
                href
                name
              }
            }
          }
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    galleryGroups: allUniquePagesJson(
      filter: { template: { eq: "galleries" } }
    ) {
      nodes {
        groups {
          label
          categories {
            imageDesktop
            imageMobile
            label
            links {
              href
              label
            }
          }
        }
      }
    }
  }
`

export default ProceduresQueryPage
