import React, { Component } from "react"
import { graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import { sortReviewsByDate } from "../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf"
// import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
// import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
// import FirstTime from "./index/FirstTime"
// import InTheCommunity from "./index/InTheCommunity"
// import ReferringProvider from "./index/ReferringProvider"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"
import { Columns, Section } from "../components/Containers"
// import TheaterVideo from "../components/Theater/TheaterVideo"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import { ProgramToast } from "../components/program-toast"
// import Line from "../components/Line"
// import Groups from "../components/Groups/Groups"
import GallerySelector from "../components/GallerySelector"

import CountAnimate from "../components/CountAnimate"
import LatestInsights from "../components/LatestInsights"
import NewsletterModal from "../components/NewsletterModal"
import CenteredCarousel from "../components/carousel/CenteredCarousel"
import InsightCard from "../components/Cards/InsightCard"
import Slider from "react-slick"
import ProcedureSelector from "../components/ProcedureSelector2"
import ReasonAccordion from "../components/accordion/ReasonAccordion"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    let services = this.props.data.serviceCategories.nodes[0].services
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    const hasReviews = this.props.data.hasReviews.nodes[0].reviews
    let allReviews = this.props.data.allReviews.nodes

    const latestBlogs = this.props.data.latestBlogs.nodes

    const featuredLogos = [
      "77P/DEV/cnn",
      "77P/DEV/nbc",
      "77P/DEV/abc",
      "77P/DEV/huffpost",
      "77P/DEV/Bloomberg",
      "77P/DEV/Healthline",
      "77P/DEV/WaPo",
      "77P/DEV/US_Weekly",
      "77P/DEV/Refinery29"
    ]

    // const logoSettings = {
    //   slidesToShow: 1,
    //   speed: 4000,
    //   autoplay: true,
    //   autoplaySpeed: 0,
    //   centerMode: true,
    //   cssEase: "linear",
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   variableWidth: true,
    //   infinite: true,
    //   initialSlide: 1,
    //   arrows: false,
    //   buttons: false
    //   // adaptiveHeight: true
    // }

    // const galleryGroups = this.props.data.galleryGroups.nodes[0].groups

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }
    return (
      <Layout
        whiteNav
        className={`main-homepage home ${language}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />
        {/* <NewsletterModal /> */}
        <Text
          as="p"
          className="home-mobile-h1 is-hidden-tablet"
          text={post.hero.heading}
        />
        <div className="" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <div className="sizzle-gradient"></div>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              language={language}
            />
          )}

          <Section colorBack colorBackSecondary smallSpacing>
            <Columns sideColumnsSize={1}>
              <div className="column">
                <div className="home__featured-media">
                  <p className="has-text-centered px-0 bold--500">
                    <span></span>
                    FEATURED ON
                    <span></span>
                  </p>
                  <div className="home__featured-media--logos is-hidden-mobile">
                    {featuredLogos.map((logo, i) => (
                      <Image
                        loading="eager"
                        data-aos-duration="1200"
                        data-aos="fade-up"
                        data-aos-delay={200 * i}
                        key={logo}
                        useAR={false}
                        publicId={logo}
                        className="home__featured-media--logo"
                      />
                    ))}
                  </div>
                </div>
                <div className="home__media-carousel is-hidden-tablet">
                  <Marquee autoFill>
                    {featuredLogos.map((logo, i) => (
                      <div key={logo}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                            padding: "0 10px"
                          }}>
                          <Image
                            loading="eager"
                            key={logo}
                            useAR={false}
                            publicId={logo}
                            className={`logo ${logo.split("77P/DEV/")[1]}`}
                          />
                        </div>
                      </div>
                    ))}
                  </Marquee>
                </div>
              </div>
            </Columns>
          </Section>

          <Section className="home__counter-container">
            <Columns>
              <div className="column home__counter px-40--mobile">
                <p className="px-0" role="heading">
                  The fan method patients and counting
                </p>
                <CountAnimate start={5000} end={10000}></CountAnimate>
              </div>
              <div className="column">
                <div
                  className="home__fan-method"
                  data-aos-duration="1200"
                  data-aos="fade-left">
                  <span role="presentation" className="mx-auto"></span>
                  <p className="large bold--400 px-0" role="heading">
                    The Fan Method™ is a scientifically proven, bespoke approach
                    to cosmetic surgery that delivers stunning natural results,
                    maximum safety, and concierge care.
                  </p>
                  <span role="presentation" className="mx-auto"></span>

                  <Button
                    buttonText="Learn More"
                    href="/the-fan-method/"
                    target="internal"
                    className="contained mx-auto"
                  />
                </div>
              </div>
            </Columns>
          </Section>

          {language == "en" && (
            <div className="home__patients">
              <Columns className="color-back clip-tr" sideColumnsSize={2}>
                <div className="column">
                  <h2>Hear from our patients. </h2>
                </div>
              </Columns>

              {hasReviews && (
                <TestimonialGrid language={language} reviews={allReviews} />
              )}

              {hasReviews && post.featuredReviews.length > 1 && (
                <div className="columns">
                  {post.featuredReviews.map(card => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <Section zeroTop zeroBottom>
            <Columns>
              <div className="column">
                <div
                  className="home__ebook"
                  data-aos-duration="1200"
                  data-aos="fade-right">
                  <div className="home__ebook-img is-hidden-mobile">
                    <Image publicId="77P/DEV/book-stack" />
                  </div>
                  <div>
                    <div className="home__ebook-img is-hidden-tablet mt-2 mb-2">
                      <Image publicId="77P/DEV/book-stack" />
                    </div>
                    <div>
                      <h3 className="px-0">
                        Before you make any decisions about cosmetic surgery,
                        arm yourself with the knowledge to choose wisely.
                      </h3>
                      <div className="button-group" style={{ gap: 20 }}>
                        <Button
                          href="/lp-14-questions-ask-choosing-plastic-surgeon-ebook/"
                          buttonText="Get your free guide here"
                          destination="internal"
                          className="contained"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column color-back color-back--gray-100">
                <div
                  className="home__reflection"
                  data-aos-duration="1200"
                  data-aos="fade-left">
                  <div className="home__reflection-content">
                    <h3 className="px-0">
                      Is The Reflection In The Mirror Calling For Change?
                    </h3>
                    <Button
                      buttonText="take a self evaluation"
                      href="https://consultation.77plasticsurgery.com/procedures"
                      destination="external"
                      className="contained mx-auto--mobile"
                    />
                  </div>

                  <div className="home__reflection-img">
                    <Image className="block" publicId="77P/DEV/reflection" />
                  </div>
                </div>
              </div>
            </Columns>
          </Section>

          <Section colorBack colorBackSecondary className="home__cta relative">
            {/* <Image
              className="home__cta--logo mb-2--mobile"
              useAR={false}
              publicId="77P/DEV/77p_logo_gold"
              data-aos-duration="1200"
              data-aos="fade-right"
            /> */}
            <Columns sideColumnsSize={2}>
              <div className="column is-one-quarter is-hidden-touch">
                <Image
                  className="mb-2--mobile"
                  // useAR={false}
                  publicId="77P/DEV/77p_logo_gold"
                  data-aos-duration="1200"
                  data-aos="fade-right"
                />
              </div>
              <div className="column is-2"></div>
              <div
                className="column"
                data-aos-duration="1200"
                data-aos="fade-left">
                <h3>Experience The Fan Method™</h3>
                <p className="mt-2 mb-3" role="heading">
                  {post.firstTimePatient.text}
                </p>
                <div className="button-group px-40--mobile">
                  {post.firstTimePatient.buttons.map(({ button }) => (
                    <Button
                      key={button.href}
                      href={button.href}
                      buttonText={button.buttonText}
                      destination={button.destination}
                      appearance="white"
                    />
                  ))}
                </div>
              </div>
            </Columns>
          </Section>

          <Section colorBack zeroBottomMobile className="relative home-fan">
            <Columns sideColumnsSize={1} className="home__about-fan">
              <div className="column home__about-images">
                <div className="home__doctor--logos color-back">
                  <Image
                    useAR={false}
                    publicId="77P/DEV/harvard-university"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/UCSf"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/castle_connolly"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/ASPS_Member"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="600"
                  />
                  <Image
                    useAR={false}
                    publicId="77P/DEV/realself-top-doctor"
                    className="home__doctor--logo"
                    data-aos-duration="1200"
                    data-aos="fade-right"
                    data-aos-delay="800"
                  />
                </div>
                <Image
                  loading="eager"
                  useAR={false}
                  publicId="77P/DEV/fan-transparent"
                  className="home__fan-image"
                  data-aos-duration="1200"
                  data-aos="fade-up"
                  data-aos-delay="800"
                />
              </div>
              <div className="column is-2"></div>
              <div
                className="column home__about-fan--content"
                data-aos-duration="1200"
                data-aos="fade-left">
                <Text
                  as="h2"
                  className="h3-style mb-2"
                  role="heading"
                  text={post.aboutDoctor.heading}
                />
                <Text role="heading" text={post.aboutDoctor.text} />
                <div className="px-40--mobile">
                  <Button
                    className="contained"
                    {...post.aboutDoctor.buttons[0].button}
                  />
                </div>
              </div>
            </Columns>
          </Section>

          {/* <GallerySelector sideColumnsSize={0} galleries={galleryGroups} /> */}

          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column">
                <div className="home__gallery">
                  <div className="home__gallery--content">
                    <h4 className="bold--500 mb-2 px-0">VIEW THE GALLERIES</h4>
                    <h3 className="px-0">
                      See the beautiful and natural results we have achieved for
                      thousands of patients.
                    </h3>
                    <Button
                      contained
                      buttonText="See our before & Afters"
                      href="/gallery/"
                      destination="internal"
                    />
                  </div>

                  <div className="home__gallery--images">
                    <Image publicId="77P/DEV/view-our-gallery-men" />
                    <Image publicId="77P/DEV/view-our-gallery-women" />
                  </div>
                </div>
              </div>
            </Columns>
          </Section>

          {/* <div className="home__services relative">
            <div
              className="column home__services--content"
              data-aos-duration="1200"
              data-aos="fade-right">
              <h3>
                Our Services
                <br />& Procedures
              </h3>
              <Button
                buttonText="Learn More"
                href="/services/"
                destination="internal"
                className="white "
              />
            </div>
          </div> */}

          <ProcedureSelector
            heading={"Our Services & Procedures"}
            blurb={
              "Please select your gender and desired procedures to learn more. "
            }
            services={services}
          />

          <Section className="home-insight" colorBack>
            <Columns sideColumnsSize={1}>
              <div className="column">
                <h2 className="has-text-centered mb-1">
                  Explore Our Latest Insights
                </h2>
                <div className="is-hidden-mobile">
                  <div className="home-insight--desktop flex align-center justify-center">
                    {latestBlogs.map((blog, idx) => (
                      <InsightCard
                        className={`card-${idx + 1}`}
                        key={blog.heading}
                        image={blog.hero}
                        heading={blog.heading}
                        href={`/${blog.title}`}
                        button={{
                          appearance: "black",
                          href: `/${blog.title}`,
                          destination: "internal",
                          buttonText: "Checkout The Blog"
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="is-hidden-tablet">
                  <CenteredCarousel>
                    {latestBlogs.map((blog, idx) => (
                      <InsightCard
                        className={`card-${idx + 1}`}
                        key={blog.heading}
                        image={blog.hero}
                        heading={blog.heading}
                        href={`/${blog.title}`}
                        button={{
                          appearance: "black",
                          href: `/${blog.title}`,
                          destination: "internal",
                          buttonText: "Checkout The Blog"
                        }}
                      />
                    ))}
                  </CenteredCarousel>
                </div>
              </div>
            </Columns>
          </Section>

          <Section>
            <Columns sideColumnsSize={3}>
              <div className="column">
                <Text as="h2" text={post.faq.heading} />
              </div>
              <div className="column is-4"></div>
            </Columns>
            <Columns sideColumnsSize={3}>
              <div className="column px-40--mobile">
                <div className="faq">
                  {post.faq.items.map((item, i) => (
                    <div key={item.question} className="mt-2">
                      <ReasonAccordion
                        // num={i + 1}
                        key={item.question}
                        heading={item.question}
                        blurb={item.answer}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Columns>
          </Section>
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    latestBlogs: allBlogsJson(
      filter: { hasThisPage: { eq: true } }
      sort: { fields: publishedDate, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        heading
        hero
        publishedDate
      }
    }
    galleryGroups: allUniquePagesJson(
      filter: { template: { eq: "galleries" } }
    ) {
      nodes {
        groups {
          label
          categories {
            imageDesktop
            imageMobile
            label
            links {
              href
              label
            }
          }
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
            button {
              title
              href
            }
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        aboutDoctor {
          heading
          text
          image
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        faq {
          heading
          items {
            question
            answer
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          # blurb
          buttonOne {
            buttonText
            href
          }
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        toast {
          hasThisSection
          text
          closeDate
        }
      }
    }

    serviceCategories: allUniquePagesJson(
      filter: { title: { eq: "services/" } }
    ) {
      nodes {
        services {
          men {
            category
            groups {
              name
              pages {
                href
                name
              }
            }
          }
          women {
            category
            groups {
              name
              pages {
                href
                name
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
