import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Section, Columns } from "../components/Containers"
import Seamless from "../components/Seamless"

const Ebook = ({ data, pageContext, location }) => {
  const ebookPage = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext

  return (
    <Layout navSpacer className="ebook-page" language={language}>
      <SEO
        title={ebookPage.metaTitle}
        description={ebookPage.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section zeroBottom className="ebook-page__heading-section relative">
        <Columns sideColumnsSize={1} className="ebook-page__heading">
          <div className="column">
            <Text as="h1" text={ebookPage.heading} />
          </div>
          <div className="column is-2"></div>
          <div className="column mt-2--mobile">
            <Image
              publicId={ebookPage.imageId}
              wrapperClassName="ebook-page__heading-image"
            />
          </div>
        </Columns>
      </Section>

      <Section zeroTop className="ebook-page__subheading-section">
        <Columns sideColumnsSize={1}>
          <div className="column ebook-page__subheading">
            <div className="ebook-page__subheading-image">
              <Image publicId="77P/DEV/book-stack" />
            </div>
            <div className="column is-1"></div>
            <div className="column ebook-page__subheading-text">
              <Text text={ebookPage.metaDescription} />
            </div>
          </div>
        </Columns>
      </Section>

      <Section zeroTop>
        <Columns sideColumnsSize={1}>
          <div className="ebook-page__content">
            <div className="ebook-page__content-text">
              <Text text={ebookPage.bodySections[0].string} />
            </div>
            <div className="ebook-page__content-form">
              <Text as="h3" text="Download for Free!" />
              <Seamless src={ebookPage.bodySections[1].string} />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query EbookPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        imageId
        bodySections {
          type
          string
        }
      }
    }
  }
`

export default Ebook
