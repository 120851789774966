import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Section = ({
  children,
  colorBack = false,
  colorBackSecondary = false,
  smallSpacing,
  zeroTop,
  zeroBottom,
  zeroTopMobile,
  zeroBottomMobile,
  zeroTopDesktop,
  zeroBottomDesktop,
  className
}) => {
  const wrapperClasses = classNames(className, {
    sctn: true,
    "sctn--small": smallSpacing,
    "color-back": colorBack,
    "color-back--gray": colorBackSecondary,
    "mt-0": zeroTop && !colorBack,
    "mb-0": zeroBottom && !colorBack,
    "pt-0": zeroTop && colorBack,
    "pb-0": zeroBottom && colorBack,
    "mt-0--mobile": zeroTopMobile && !colorBack,
    "mb-0--mobile": zeroBottomMobile && !colorBack,
    "pt-0--mobile": zeroTopMobile && colorBack,
    "pb-0--mobile": zeroBottomMobile && colorBack,
    "mt-0--desktop": zeroTopDesktop && !colorBack,
    "mb-0--desktop": zeroBottomDesktop && !colorBack,
    "pt-0--desktop": zeroTopDesktop && colorBack,
    "pb-0--desktop": zeroBottomDesktop && colorBack
  })

  return <section className={wrapperClasses}>{children}</section>
}

export default Section

Section.propTypes = {
  children: PropTypes.node.isRequired,
  colorBack: PropTypes.bool,
  smallSpacing: PropTypes.bool,
  zeroTop: PropTypes.bool,
  zeroBottom: PropTypes.bool
}
