import React, { useState } from "react"
import { Columns } from "./Containers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"

import { Image, Text } from "./Core"
import { Link } from "gatsby"

const GallerySelector = ({ galleries, sideColumnsSize = 2, onlyCards }) => {
  const [gender, setGender] = useState("women")
  const [activeCard, setActiveCard] = useState(null)

  const group = galleries.find(gal => gal.label.toLowerCase() === gender)

  return (
    <Columns sideColumnsSize={sideColumnsSize} className="gs">
      {!onlyCards && (
        <div className="column">
          <div className={`gs__content ${gender}`}>
            <h4>VIEW THE GALLERIES</h4>
            <h3>
              See the beautiful and natural results we have achieved for
              thousands of patients.
            </h3>

            <div className="button-group is-centered">
              <button
                className={`gs__gender-btn ${
                  gender !== "women" ? "disabled" : ""
                }`}
                onClick={() => {
                  setGender("women")
                  setActiveCard(null)
                }}>
                WOMEN
              </button>
              <button
                className={`gs__gender-btn ${
                  gender !== "men" ? "disabled" : ""
                }`}
                onClick={() => {
                  setGender("men")
                  setActiveCard(null)
                }}>
                MEN
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="column mt-2--mobile">
        <div className={`gs__cards relative ${gender}`}>
          {group.categories.map((cat, idx) => (
            <React.Fragment key={cat.label}>
              <button
                onClick={() => setActiveCard(group.categories[idx])}
                className="gs__card-btn reset relative">
                <div className="gs__card-label-container">
                  <Text className="gs__card-label" as="p" text={cat.label} />

                  <div className="gs__card-label-btn">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
                <Image
                  className="block"
                  // useAR={false}
                  publicId={cat.imageDesktop}
                />
              </button>

              <div className={`gs__links ${activeCard ? "active" : ""}`}>
                {activeCard && (
                  <button
                    onClick={() => setActiveCard(null)}
                    className="reset go-back-button">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                )}
                {activeCard && (
                  <>
                    <p
                      className="has-text-centered bold uppercase mb-2"
                      style={{ fontSize: "16px" }}>
                      VIEW {activeCard.label.replace("<br>", " ")} Gallery
                    </p>

                    <div className="gs__gallery-pages">
                      {activeCard.links.map(lk => (
                        <Link
                          className="gs__gallery-link"
                          key={lk.href}
                          to={"/" + lk.href}>
                          {lk.label}
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </Columns>
  )
}

export default GallerySelector
