import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const QA = () => {
  const data = useStaticQuery(
    graphql`
      query ProceduresQuery {
        allProceduresJson {
          nodes {
            title
            name
          }
        }
      }
    `
  )
  const s = data.allProceduresJson.nodes
  return (
    <div style={{ margigTop: 20 }}>
      <h1>services</h1>
      <div style={{ padding: "50px 20px" }}>
        {s.map((serv, i) => (
          <Link
            style={{
              display: "block",
              maxWidth: "max-content",
              margin: "10px 0"
            }}
            to={"/" + serv.title}
            key={serv.title}>
            <p>{serv.name}</p>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default QA
