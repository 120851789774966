import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"

import { Heading } from "../components/Hero"
import { Text, Image } from "../components/Core"
import { Section, Columns } from "../components/Containers"
import Layout from "../components/layout"

import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import { Button, ButtonGroup } from "../components/Button"
import { CallToAction } from "../components/CTA"
import SEO from "../components/seo"

const FirstVisitPage = ({ data, location, pageContext }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  return (
    <Layout
      navSpacer
      language={pageContext.language}
      pageTitle="first-visit"
      pageHeading={post.heading}
      footerColorBack>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={pageContext.language}
      />
      <Heading sideColumnsSize={3} heading={post.heading} blurb={post.blurb} />

      <Section className="mt-1">
        <Columns sideColumnsSize={3}>
          <div className="column">
            {post.sections.map((sect, i) => (
              <div
                className={`first-visit__section ${
                  sect.image ? "has-image" : ""
                } ${sect.colReverse ? "reverse" : ""}`}
                key={i}>
                <div className="first-visit__content">
                  <Text useStringReplace text={sect.text} />

                  {sect.list.listItems && sect.list.listItems.length >= 1 && (
                    <ul
                      className={`first-visit__list first-visit__list--${
                        sect.list.isHorizontal & !sect.image
                          ? "horizontal"
                          : "vertical"
                      }`}>
                      {sect.list.listItems.map((item, i) => (
                        <li key={i}>
                          <FontAwesomeIcon icon={faArrowRight} />
                          <Text as="span" text={item} />
                        </li>
                      ))}
                    </ul>
                  )}

                  {sect.buttons && sect.buttons.length >= 1 && (
                    <ButtonGroupMap
                      buttons={sect.buttons}
                      className="px-40--mobile"
                    />
                  )}
                </div>
                {sect.image && <Image publicId={sect.image} />}
              </div>
            ))}
          </div>
        </Columns>
      </Section>

      <Section zeroBottom className="single-review__cta">
        <Columns sideColumnsSize={2}>
          <div className="column single-review__cta--content">
            <Text
              style={{ fontSize: "38px" }}
              as="h3"
              text={getStartedData.blurb}
            />
            <ButtonGroup className="px-40--mobile">
              {getStartedData.buttons.map(({ button }) => (
                <Button
                  key={button.href}
                  buttonText={button.buttonText}
                  href={button.href}
                  destination={button.destination}
                  contained
                />
              ))}
            </ButtonGroup>
          </div>
          <div style={{ marginTop: "auto" }} className="column mt-3--mobile">
            <div>
              <Image publicId="77P/DEV/about-doctor-fan" />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export default FirstVisitPage

export const pageQuery = graphql`
  query yourFirstVisitPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        metaTitle
        metaDescription
        sections {
          text
          list {
            isHorizontal
            # listItems
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          image
          colReverse
        }
      }
    }
    allTemplateSettingsJson(filter: { templateType: { eq: "single-review" } }) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
  }
`
