import React, { useState } from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { Image, Text } from "../components/Core"
import { Button, ButtonGroupMap } from "../components/Button"
import { Columns, Section } from "../components/Containers"
import TheaterVideo from "@components/Theater/TheaterVideo"
import SizzleVideo from "../components/reactPlayerTest"
import AccordionBase from "../components/accordion/AccordionBase"
import SideBySide from "../components/carousel/SideBySide"
import ReasonAccordion from "../components/accordion/ReasonAccordion"

const AboutUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const team = data.team.nodes[0].meetOurTeam.staffImages
  return (
    <Layout navSpacer language={language} className="about-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content about-us">
        {/* <Sizzle playing youtube={post.youtube} language={language}>
          <StaticHeading
            heading={post.heading}
            subheading={post.topBlurb}
            language={language}
          />
        </Sizzle> */}

        <Section smallSpacing>
          <Columns sideColumnsSize={2}>
            <div className="column about-us__heading">
              <Text className="px-40--mobile" as="h1" text={post.heading} />
            </div>
          </Columns>
        </Section>

        <Section zeroTop colorBack className="about-us__sizzle relative">
          <Columns sideColumnsSize={2}>
            <div className="column">
              {/* <Sizzle
                playing
                youtube={post.youtube}
                language={language}></Sizzle> */}
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                overVideo
                iconOnly
                language={language}
                buttonClass="contained">
                <SizzleVideo />
              </TheaterVideo>
              <Text as="p" text={post.topBlurb} />
            </div>
            <div className="column is-2"></div>
          </Columns>
        </Section>

        <Section zeroTop colorBack className="about-us__doctor">
          <Columns sideColumnsSize={2} className="is-vcentered">
            <div className="column">
              <Text text={post.meetOurDoctors.text} />
              <div className="px-40--mobile">
                <Button
                  className="mx-auto--mobile"
                  destination="internal"
                  appearance="contained"
                  buttonText="Learn More about Dr. Fan"
                  href={post.meetOurDoctors.images[0].doctor.url}
                />
              </div>
            </div>
            <div className="column">
              <div className="about-us__doctor--image mt-2--mobile">
                <Image
                  publicId={post.meetOurDoctors.images[0].doctor.imageId}
                />
              </div>
            </div>
          </Columns>
        </Section>

        <Section zeroTopMobile>
          <Columns sideColumnsSize={2} className="is-vcentered">
            <div className="column is-two-fifths mb-2--mobile px-40--mobile">
              <Image publicId={post.ourStory.image} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <Text as="h2" text={post.ourStory.heading} />
              <Text className="mt-2" text={post.ourStory.text} />
            </div>
          </Columns>
        </Section>

        <Section
          colorBack
          colorBackSecondary
          className="about-us__accreditation">
          <Columns sideColumnsSize={2}>
            <div className="column has-text-centered">
              <div
                className="mb-2"
                style={{ width: "100%", maxWidth: 150, marginInline: "auto" }}>
                <Image publicId={post.accreditations.logo} />
              </div>
              <Text
                className="color-white"
                as="h2"
                text={post.accreditations.heading}
              />
              <Text
                className="mt-2 color-white"
                text={post.accreditations.text}
              />
            </div>
          </Columns>
        </Section>

        <Section>
          <Columns sideColumnsSize={3}>
            <div className="column">
              <Text
                className="has-text-centered"
                as="h2"
                text={post.reasonsWhySection.heading}
              />

              <div className="about-us__reasons">
                {post.reasonsWhySection.reasonsWhyColumn.map((reason, i) => (
                  <ReasonAccordion
                    num={i + 1}
                    key={reason.heading}
                    heading={reason.heading}
                    blurb={reason.blurb}
                  />
                ))}
              </div>
            </div>
          </Columns>
        </Section>

        <Section>
          <SideBySide
            slides={[
              {
                text: "We believe that everyone should be comfortable in their own skin.",
                image: "77P/DEV/77-core-value-one"
              },
              {
                text: "We believe that deciding to change your appearance is very personal. We will respect your values, wishes, and privacy.",
                image: "77P/DEV/77-core-value-two"
              },
              {
                text: "We believe that you have unique needs and goals. Your unique beauty demands equally unique treatments. Unlike many practices, we will not recommend the same treatment or procedure for everyone.",
                image: "77P/DEV/77-core-value-three"
              },
              {
                text: "We believe in real science and real results. You won’t find us supporting the latest fad or trend unless we know that it makes sense and indeed works. We only offer treatments that we stand behind.",
                image: "77P/DEV/77-core-value-four"
              },
              {
                text: "We believe in carrying out every treatment with the utmost precision, expertise, and care, just as we would for our family. Sometimes our procedures may take longer to perform, but that extra attention to detail shows in our results and is well worth it.",
                image: "77P/DEV/77-core-value-five"
              },
              {
                text: "We believe in doing no harm (primum non nocere). Your safety is one of our highest priorities. We only use FDA-approved products, full accredited operating room facilities, and board-certified anesthesiologists. If you are making a choice that is hazardous to your health or your appearance, we will let you know.",
                image: "77P/DEV/77-core-value-six"
              },
              {
                text: "We believe that less is more. We will recommend the simplest, safest, and most effective treatment needed to meet your goals.",
                image: "77P/DEV/77-core-value-seven"
              },
              {
                text: "We believe that there is a purpose for both nonsurgical and surgical cosmetic procedures. Because we offer both types of treatments, you can rest assured that you will receive the best kind of treatment for your specific goals.",
                image: "77P/DEV/77-core-value-eight"
              },
              {
                text: "We believe in honesty. We will tell you realistically what to expect every step of the way. Plastic surgery isn’t for everyone, and we won’t be afraid to tell you if we think you’re not a good fit. We don’t sugarcoat. We will tell you what you need to hear, even if you don’t want to hear it. That might not make us well suited for everyone, but it’s in our DNA.",
                image: "77P/DEV/77-core-value-nine"
              }
            ]}
          />
        </Section>

        {/* <MeetOurTeam
          colorBack={false}
          post={post}
          team={team}
          language={language}
        /> */}

        <Section zeroBottom className="about-us__cta">
          <Columns sideColumnsSize={1} className="is-vcentered is-desktop">
            <div className="column about-us__cta--image">
              <Image publicId="77P/DEV/boost-your-confidence" />
            </div>
            <div className="column is-2"></div>
            <div className="column about-us__cta--content">
              <Text as="h3" text={post.callToAction.heading} />
              <Text className="mt-2" as="p" text={post.callToAction.blurb} />
              <ButtonGroupMap buttons={post.callToAction.buttons} />
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        ourStory {
          heading
          text
          image
        }
        ourValues {
          heading
        }
        accreditations {
          heading
          text
          logo
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            blurb
            heading
            number
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        textOverlappedImages {
          hasThisSection
          items {
            isReversed
            heading
            blurb
            imageId
            button {
              destination
              appearance
              buttonText
              href
            }
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    team: allUniquePagesJson(
      filter: { template: { eq: "about-us" }, language: { eq: "en" } }
    ) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
              spanishTitle
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
