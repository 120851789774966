import React from "react"
import "../../components/animations/scroll.scss"
import PropTypes from "prop-types"
// import TheaterVideo from "@components/Theater/TheaterVideo"
import { Text } from "../../components/Core"

const StaticHeading = ({ heading, subheading, youtube, language }) => {
  return (
    <div
      style={{
        textAlign: "left"
      }}
      data-aos-duration="1200"
      data-aos="fade-up"
      data-aos-delay="200"
      className="static-heading">
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset", textAlign: "left" }}>
        <Text
          role="heading"
          className="curtain-heading h3-style mb-1 is-hidden-mobile"
          as="h1"
          text={heading}
        />
        <Text
          useStringReplace
          className="above-text__subheader"
          as="span"
          text={subheading}
        />
      </div>

      {/* {youtube && youtube !== "?" && (
        <div className="button-group tight mt-3 is-hidden-mobile">
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
            controls
            playing
            iconOnly
            sizzleButton
            buttonClass="sizzle-button-under"
            compId="sizzleTheaterButton"
            language={language}
          />
          <p className="sizzle-button-subtext bold--500">WATCH & HEAR MORE</p>
        </div>
      )} */}
    </div>
  )
}

StaticHeading.propTypes = {
  language: PropTypes.string
}

export default StaticHeading
