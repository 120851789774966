import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import "./pagination.scss"

const Pagination = ({
  currRange,
  currPage,
  totalPages,
  onPrevClick,
  onNextClick,
  onPageClick
}) => {
  const getPages = () => {
    let numberArrs = new Array(currRange[1] - currRange[0])
    for (let i = currRange[0]; i <= currRange[1]; i++) {
      numberArrs[i] = (
        <button
          key={i}
          aria-label={`page ${i + 1}`}
          className={`pagination__page-btn ${
            currPage !== i ? "pagination__inactive-btn" : ""
          }`}
          onClick={() => onPageClick(i)}>
          {i + 1}
        </button>
      )
    }
    return numberArrs
  }

  return (
    <div className="pagination">
      <button
        disabled={currPage === 0}
        className="pagination__arrow-btn"
        aria-label="previous page"
        onClick={onPrevClick}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      {getPages()}
      <button
        disabled={currPage === totalPages - 1}
        className="pagination__arrow-btn"
        aria-label="next page"
        onClick={onNextClick}>
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  )
}
export default Pagination
