import React, { useRef, useState } from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { Image } from "../Core"

import "./_SideBySide.scss"

const SideBySide = ({ slides }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  let sliderRef = useRef(null)

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    // afterChange: current => {
    //   setActiveSlide(current)
    //   console.log(current)
    //   console.log(activeSlide)
    // }
    beforeChange: (current, next) => setTimeout(() => setActiveSlide(next), 500)
  }

  const next = () => {
    // if (activeSlide === slides.length - 1) return
    sliderRef.slickNext()
    setActiveSlide(null)
  }
  const previous = () => {
    // if (activeSlide === 0) return
    sliderRef.slickPrev(null)
    setActiveSlide(null)
  }

  return (
    <div className="sxs">
      <div className="sxs__content">
        <h4 className="sxs__label uppercase px-0">OUR VALUES</h4>
        <div className={`${activeSlide && "sxs__fade-in"}`}>
          <p className="sxs__number px-0">
            {activeSlide !== null && activeSlide + 1}
          </p>
          <p className="sxs__text px-0">{slides[activeSlide]?.text}</p>
        </div>
        <div className="button-group" style={{ flexDirection: "row" }}>
          <button
            // disabled={activeSlide === 0}
            className="reset sxs__slide-btn"
            aria-label="previous slide"
            onClick={previous}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            // disabled={activeSlide === slides.length - 1}
            className="reset sxs__slide-btn"
            aria-label="next slide"
            onClick={next}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
      <div className="sxs__slider">
        <Slider
          ref={slider => {
            sliderRef = slider
          }}
          {...settings}>
          {slides.map(slides => (
            <React.Fragment key={slides.image}>
              <Image publicId={slides.image} />
            </React.Fragment>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default SideBySide
