import React from "react"
import Slider from "react-slick"

const CenteredCarousel = ({ children }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "30px"
        }
      }
    ]
  }
  return (
    <div className="centered-carousel">
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default CenteredCarousel
