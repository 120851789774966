import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"

import "../css/pages/FanMethod.scss"

const FanMethodPage = () => (
  <Layout navSpacer language="en" className="fan-method">
    <SEO
      title="The Fan Method"
      description="The Fan Method is a scientifically proven, bespoke approach to cosmetic surgery that delivers stunning natural results, maximum safety, and concierge care."
    />
    <Section smallSpacing>
      <Columns sideColumnsSize={2} className="fan-method__header-container">
        <div className="column">
          <div className="fan-method__hero">
            <p className="large px-0">The Fan Method</p>
            <h1 className="mt-2">Your Pathway to Natural Beauty</h1>
            <p className="large px-0">
              The Fan Method is a scientifically proven, bespoke approach to
              cosmetic surgery that delivers stunning natural results, maximum
              safety, and concierge care.
            </p>
          </div>
        </div>
        <div className="column">
          <div className="fan-method__fan">
            <Image publicId="77P/DEV/doctor-fan-bio" />
          </div>
        </div>
      </Columns>
    </Section>

    <Section colorBack className="fan-method__stats-container">
      <div className="fan-method__stats fan-method__center-align">
        <h2 className="px-0 has-text-centered">
          The Fan Method vs Traditional Plastic Surgery
        </h2>

        <table className="mt-2">
          <tr>
            <th>Feature</th>
            <th>Traditional Plastic Surgery</th>
            <th>The Fan Method</th>
          </tr>
          <tr>
            <td>Patient Satisfaction</td>
            <td>Avg 85-95%</td>
            <td>97% satisfaction rate</td>
          </tr>
          <tr>
            <td>Experience</td>
            <td>Varies</td>
            <td>20 years, 10,000+ patients</td>
          </tr>
          <tr>
            <td>Natural Results</td>
            <td>Often Artificial Looking</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Rapid Recovery</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Bespoke Customization</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Advanced Technology including Regenerative Medicine</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>4D Simulation Technology</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Minimal Access Surgery</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>42 Point Safety Protocol</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Technique</td>
            <td>Speed Focused</td>
            <td>Precision and Results Focused</td>
          </tr>
          <tr>
            <td>Master Artistry</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>24/7 Patient Concierge</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Comprehensive Beautiful Inside & Out Approach</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Reoperation Rates</td>
            <td>Avg 10-30%</td>
            <td>3%</td>
          </tr>
        </table>
      </div>
    </Section>

    <Section zeroTop zeroBottom colorBack>
      <Columns sideColumnsSize={1} className="is-vcentered is-desktop">
        <div className="column fan-method__cta--image">
          <Image publicId="77P/DEV/reach-out-to-77-plastics" />
        </div>
        <div className="column fan-method__cta--content">
          <Text as="h2" text={"Ready to Start?"} />
          <Text
            as="p"
            text={
              "Call us today for your complimentary consultation or schedule a complimentary consultation online and see Crisalix in action for yourself. Our expert plastic surgeons are ready to help you plan for your journey for a new you."
            }
            className="fan-method__cta--text"
          />
          <div className="fan-method__button-group">
            <Button
              buttonText="CONTACT US"
              href="/contact-us/"
              appearance="contained"
            />
            <Button
              buttonText="BOOK A VIRTUAL CONSULTATION"
              href="/contact-us/"
              appearance="contained"
              className="fan-method__zero-margin-left"
            />
          </div>
        </div>
      </Columns>
    </Section>
  </Layout>
)

export default FanMethodPage
