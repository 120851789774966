import React, { useEffect, useState } from "react"
import Dialog from "./Modals/Dialog"
import { Button } from "./Button"

import "./NewsletterModal.scss"

const NewsletterModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const LS_KEY = "77newsletter"

  useEffect(() => {
    const ls = localStorage.getItem(LS_KEY)
    const hasSignedUp = JSON.parse(ls)

    if (!hasSignedUp) {
      setTimeout(() => {
        setIsOpen(true)
      }, 5000)
    }
  }, [])

  const handleLocalStorage = () => {
    localStorage.setItem(LS_KEY, "true")
  }

  return (
    <Dialog
      id="modal-top"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="newsletter">
      <div className="newsletter__body">
        <div className="newsletter__left">
          <h4 className="uppercase bold--600">Subscribe to our newsletter</h4>

          <h3 className="mt-2 bold--600">
            Stay at the forefront of beauty, wellness, and longevity
          </h3>

          <h4 className="mt-2 bold--600">Get Curated Knowledge</h4>
          <p>
            Receive meticulously researched articles, cutting-edge insights, and
            personal anecdotes from Dr. Larry Fan.
          </p>

          <h4 className=" bold--600">Exclusive Content</h4>
          <p>
            Enjoy early access to our podcast series, interviews with industry
            leaders, and behind-the-scenes looks at the latest aesthetic trends.
          </p>

          <h4 className=" bold--600">Empower Your Decisions</h4>
          <p>
            With every issue, gain empowering tips and guidance to make informed
            choices about your beauty and health regimen.
          </p>
          <div onClick={handleLocalStorage}>
            <Button
              contained
              href="/"
              destination="external"
              buttonText="Sign Up"
            />
          </div>
        </div>
        <div className="newsletter__right">
          <div className="nlr-1">image</div>
          <div className="nlr-2">image</div>
          <div className="nlr-3">
            <p className="px-0 bold--500">
              Embark on a journey of discovery with "Beauty and Beyond"—your
              essential newsletter that delves into the art and science of
              plastic surgery, the secrets to timeless aesthetics, and how to
              live longer and better. Authored by Dr. Larry Fan and a panel of
              renowned experts, this is your window into a world where beauty
              meets innovation and health.
            </p>
          </div>
          <div className="nlr-4">image</div>
        </div>
      </div>
    </Dialog>
  )
}

export default NewsletterModal
