import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/Seamless"
import { Text, Image } from "../components/Core"
import { Columns, Section } from "../components/Containers"

const SelfEvaluation = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  return (
    <Layout
      navSpacer
      pageTitle="self-eval-page"
      language={pageContext.language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={pageContext.language}
      />

      <Section className="self-eval-page__heading-container relative">
        <Columns sideColumnsSize={1}>
          <div className="column self-eval-page__header">
            <div className="self-eval-page__heading">
              <Text as="h1" text="Your candidacy for cosmetic surgery" />
              <Text
                text={`Fill out our form to determine whether you are a candidate for a Cosmetic Procedure. After completing this evaluation, you will receive a general explanation of the questions and a personalized result based on your answers. You also have the option of receiving a call to discuss your results.\n\nThis evaluation should take no more than 2 minutes.`}
              />
            </div>
            <div className="self-eval-page__image">
              <Image
                publicId="77P/DEV/77-self-evaluation"
                wrapperClassName="self-eval-page__image"
              />
            </div>
          </div>
        </Columns>
      </Section>

      <Section colorBack className="self-eval-page__form-container">
        <Columns sideColumnsSize={1}>
          <div className="column self-eval-page__columns">
            <Text
              as="h2"
              text="Find more information about your areas of interest and whether you’re qualified for cosmetic procedures."
            />

            {post.bodySections[1] && post.bodySections[1].string && (
              <Seamless
                src={post.bodySections[1].string}
                className="self-eval-page__form"
              />
            )}
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const evaluationPageQuery = graphql`
  query selfEvaluationPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        bodySections {
          type
          string
          code
          # youtube
          # image
        }
      }
    }
  }
`

export default SelfEvaluation
