import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"

import "../css/pages/FanMethod.scss"
import { SocialIconLink } from "../components/SocialReviews"

import "../css/pages/rise.scss"
const RiseProgram = () => (
  <Layout whiteNav language="en" className="rise">
    <SEO
      title="RISE | 77 Plastic Surgery"
      description="Share your story by August 31st for a chance to receive FREE reconstructive plastic surgery."
    />
    <div className="relative">
      <Image
        wrapperClassName="is-hidden-touch"
        publicId="77P/DEV/PROGRAMS/rise_hero_desktop"
      />
      <Image
        wrapperClassName="is-hidden-desktop"
        publicId="77P/DEV/PROGRAMS/rise_hero_mobile"
      />
      <div className="column rise__heading">
        <div className="mb-2 is-hidden-touch" style={{ maxWidth: "60px" }}>
          <Image publicId="77P/DEV/PROGRAMS/purple_ribbon" />
        </div>
        {/* <h1>
          We recognize the courage it takes to survive domestic violence, and we
          want to support your healing journey. Share your story by August 31st
          for a chance to receive FREE reconstructive plastic surgery.
        </h1> */}
        <h1>
          <b>
            RISE applications are now closed. Thank you to everyone who shared
            their stories—you inspire us every day.
          </b>{" "}
          We’re reviewing applications and will keep you updated as we continue
          our mission to support survivors of domestic violence.
        </h1>
        {/* <Button
          buttonText="Apply now"
          href="https://secureform.seamlessdocs.com/f/77plasticsrise"
          className="black"
          destination="external"
        /> */}
      </div>
    </div>

    <Section>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="rise__grid-half">
            <div>
              <h2>RISE</h2>
              <p>
                Domestic violence is a concern in every community. 77 Plastic
                Surgery developed RISE to empower survivors of domestic abuse
                and restore confidence by honoring their bravery. To raise
                awareness about domestic violence, 77 Plastic Surgery will hear
                stories from local survivors and perform free restorative and
                reconstructive care for a deserving individual.
              </p>
              <p>
                RISE encourages all voices to speak out against domestic abuse
                to break the cycle of an often-silent reality. By sharing your
                story, you inspire hope in others.
              </p>
            </div>
            <div className="has-text-centered">
              {/* <h3>
                Share your story for a chance to receive free reconstructive
                plastic surgery.
              </h3> */}
              {/* <p>
                Submissions are open to all residents in and around the San
                Francisco area.
              </p> */}
              <p>
                Thank you for your interest in the RISE program. The application
                period is now closed. Applicants will be notified once the
                selection process has been completed.
              </p>
              <div
                className="mt-2 mb-2"
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#000"
                }}></div>
              {/* <div className="standard-button contained mx-auto mb-2">
                SHARE YOUR STORY
              </div> */}
              <div className="rise__share">
                <p>Updates will be shared on</p>
                <div>
                  <SocialIconLink
                    platform="Facebook"
                    link="https://www.facebook.com/77PlasticSurgery/"
                  />
                  <SocialIconLink
                    platform="Instagram"
                    link="https://www.instagram.com/dr415/"
                  />
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </Columns>
    </Section>

    <Section colorBack className="rise__facts">
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="rise__grid-half">
            <div>
              <Image
                wrapperClassName="is-hidden-desktop mb-2"
                publicId="77P/DEV/PROGRAMS/rise_graphic1_mobile"
              />
              <h2>Did You Know?</h2>
              <Image
                wrapperClassName="is-hidden-touch"
                publicId="77P/DEV/PROGRAMS/rise_graphic1_desktop"
              />
            </div>
            <div className="flex" style={{ alignItems: "center", gap: "10px" }}>
              <ul>
                <li>
                  On average, 24 people per minute are victims of rape, physical
                  violence, or stalking by an intimate partner in the United
                  States—more than 12 million women and men over the course of a
                  year.
                </li>
                <li className="mt-1">
                  1 in 4 women (24.3%) and 1 in 7 men (13.8%) aged 18 and older
                  in the United States have been the victim of severe physical
                  violence by an intimate partner in their lifetime.
                </li>
                <li className="mt-1">
                  More than 1 in 3 women (35.6%) and more than 1 in 4 men
                  (28.5%) in the United States have experienced rape, physical
                  violence, and/or stalking by an intimate partner in their
                  lifetime.
                </li>
              </ul>
            </div>
          </div>
          <div></div>
        </div>
      </Columns>
    </Section>

    <Section colorBack className="rise__help  angle-up">
      <Columns sideColumnsSize={2}>
        <div className="column">
          <h2>Get Help</h2>
          <div className="mt-1 rise__grid-third">
            <div>
              <p>
                Every relationship is different, and domestic violence doesn't
                always look the same. The{" "}
                <a
                  className="rise__inlink"
                  href="https://www.thehotline.org/"
                  title="Learn more about the National Domestic Violence Hotline"
                  target="_blank">
                  National Domestic Violence Hotline
                </a>{" "}
                is available 24 hours a day, 7 days a week, 365 days a year. If
                you or someone you know needs help, please call, text, or chat.
              </p>
            </div>
            <div
              className="rise__grid-half rise__help-links"
              style={{ gap: "30px" }}>
              <div>
                <div style={{ maxWidth: "100px" }}>
                  <Image publicId="77P/DEV/PROGRAMS/thehotline-logo" />
                </div>
              </div>
              <div>
                <p>Chat</p>
                <p>
                  <a
                    className="flex align-center"
                    style={{ gap: 10 }}
                    href="https://www.thehotline.org/"
                    target="_blank">
                    <span className="icon">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>{" "}
                    thehotline.org
                  </a>
                </p>
              </div>
              <div>
                <p>Call</p>
                <p>
                  <a href="tel:18007997233">1-800-799-7233</a>
                </p>
              </div>
              <div>
                <p>Text</p>
                <p>"START" to 88788</p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </Columns>
    </Section>

    <Section zeroBottom className="rise__cta">
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="rise__grid-half">
            <div style={{ marginTop: "auto" }}>
              <Image publicId="77P/DEV/about-doctor-fan" />
            </div>
            <div className="relative" style={{ zIndex: 2 }}>
              <h4>About Dr. Fan</h4>
              <p>
                As a renowned plastic surgeon, Dr. Larry Fan witnesses the
                impact of domestic violence in the greater San Francisco Bay
                area. Driven by his passion to make a difference in the lives of
                others, he has dedicated his career to helping individuals
                achieve their desired appearance and regain self-confidence.
                Because he understands the emotional toll physical deformities
                caused by domestic violence can take, Dr. Fan helps his patients
                unlock newfound confidence, positively impacting their lives. He
                and his team at 77 Plastic Surgery are committed to providing a
                supportive and empathetic environment, where each patient is
                educated and empowered on their healing journey. As part of the
                RISE program, Dr. Fan will perform free reconstructive treatment
                for one survivor of domestic abuse.
              </p>
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  </Layout>
)

export default RiseProgram
