import React, { useState, useEffect } from "react"
import { Text } from "../Core"

import "./ProgramToast.scss"

const ProgramToast = ({ closeDate, children }) => {
  if (!children) return null

  const [close, setClose] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 600) {
      setIsOpen(true)
    }
  }

  const today = new Date()
  const end = new Date(closeDate)

  if (close || today > end) return null

  // if (!isOpen) {
  //   return null
  // }

  return (
    <div className={`program-toast ${isOpen ? "open" : ""}`}>
      <span className="close-toast" onClick={() => setClose(true)}>
        ✖
      </span>
      <Text text={children} />
    </div>
  )
}

export default ProgramToast
