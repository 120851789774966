import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Image, Text } from "../components/Core"
import { Office, Hours, Phone } from "../components/locations"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import useForms from "../hooks/useForms"
import { Stars } from "../components/SocialReviews"
import useSocialData from "../hooks/useSocialData"
import Spinner from "../components/Loading/Spinner"

const ContactUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const translations = data.translations.nodes[0]

  const { form } = useForms(post.formId)

  const locations = data.allLocationsJson.nodes

  let [isFormLoaded, setIsFormLoaded] = useState(false)

  return (
    <Layout navSpacer className="contact-us" language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      {/* <Heading heading={post.contactBody.heading} /> */}

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column has-text-centered">
            <Text as="h1" className="mt-0 px-40--mobile" text={post.heading} />
            {post.contactBody.subheading && (
              <Text as="h2" text={post.contactBody.heading} />
            )}
            <Text text={post.contactBody.blurb} />
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column contact-us__content-container">
            <div className="column">
            <Image className="splash" publicId={post.heroImage} useAR />
              <div className="contact-us__sidebar is-hidden-touch">
                {/* <Text as="h5" text={translations.address[language]} /> */}
                {locations.map((local, i) => {
                  const { social } = useSocialData(local.locationData.gmbId)

                  return (
                    <div className="contact-us__sidebar-address" key={i}>
                      <p className="bold mb-1">
                        {!local.pageData.hasThisPage ? (
                          `${local.locationData.locationName}`
                        ) : (
                          <Link
                            to={`/${local.pageData.title}`}
                            title={`${translations.learnMoreAboutOur[language]} ${local.locationData.locationName} ${translations.office[language]}`}>{`${local.locationData.locationName}`}</Link>
                        )}
                      </p>
                      <Office
                        language={language}
                        city={local.locationData.cityName}
                        state={local.locationData.stateAbbr}
                        zip={local.locationData.cityZip}
                        streetName={local.locationData.streetName}
                        streetNumber={local.locationData.streetNumber}
                        gmb={local.locationData.googlePlaceLink}
                      />

                      <Text
                        className="mt-1 mb-0"
                        as="h5"
                        text={translations.hours[language]}
                      />
                      <Hours
                        key={i}
                        language={language}
                        locationHours={local.locationData.locationHours}
                      />

                      {local.locationData.phone && (
                        <>
                          <Text
                            className="mt-1 mb-0"
                            as="h5"
                            text={translations.phone[language]}
                          />
                          <p className="my-0">
                            <Phone phoneNumber={local.locationData.phone} />
                          </p>
                        </>
                      )}
                      {social && social.rating && (
                        <>
                          <p
                            style={{ fontSize: "16px", marginBottom: "5px" }}
                            className="bold">
                            Google
                          </p>
                          <a
                            target="_blank"
                            className="mt-0 mb-3 contact-us__sidebar-ratings"
                            href={social.url}
                            title={`View our reviews`}>
                            <Stars rating={social.rating} />
                            <span>({social.totalReviews})</span>
                          </a>
                        </>
                      )}
                    </div>
                  )
                })}
                {/*
              <Text as="h5" text={translations.hours[language]} />
              <Hours locationHours={locations[0].locationData.locationHours} /> */}

                {/* <Text as="h5" text={translations.phone[language]} />
              {locations.map((l, i) => (
                <p key={i} className="my-0">
                  <Phone phoneNumber={l.locationData.phone} />
                </p>
              ))} */}
              </div>
            </div>
            {language === "en" && (
              <div className="contact-us__form">
                {!isFormLoaded && (
                  <div className="loading-spinner-container">
                    <Spinner />
                  </div>
                )}
                <iframe
                  onLoad={() => setIsFormLoaded(true)}
                  src="https://api.leadconnectorhq.com/widget/form/7SNYtv79Wojy4eDX4zWg"
                  style={{
                    width: "100%",
                    border: "none",
                    borderRadius: "3px",
                    position: "relative",
                    top: "-70px",
                  }}
                  id="inline-7SNYtv79Wojy4eDX4zWg"
                  data-form-id="7SNYtv79Wojy4eDX4zWg"
                  title="✅ Call Back Form"></iframe>
                <script src="https://link.msgsndr.com/js/form_embed.js"></script>
              </div>
            )}
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heroImage
        metaTitle
        metaDescription
        heading
        contactBody {
          heading
          blurb
        }
        formId
      }
    }
    allLocationsJson(
      filter: { useOnFooter: { eq: true } }
      sort: { fields: locationData___order }
    ) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          hasThisPage
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
    forms: allDataJson(filter: { optionsItem: { eq: "seamless" } }) {
      nodes {
        allSeamlessForms {
          embedUrl
          seamlessShortcode
          title
        }
      }
    }
    translations: allTranslationsJson {
      nodes {
        address {
          en
          es
        }
        hours {
          en
          es
        }
        phone {
          en
          es
        }
        office {
          en
          es
        }
        learnMoreAboutOur {
          en
          es
        }
      }
    }
  }
`

export default ContactUsPage
