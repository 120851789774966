import React from "react"
import { Button } from "../Button"

const CandidateEval = () => {
  return (
    <div
      className="has-text-centered color-back"
      style={{
        backgroundColor: "#fff",
        padding: 40
      }}>
      <h2>Are You A Candidate?</h2>
      <p style={{ fontSize: "18px" }}>
        Our Cosmetic Self Evaluation can help you decide if this procedure is
        right for you:{" "}
      </p>
      <Button
        className="mx-auto large-button"
        href="/cosmetic-self-evaluation/"
        buttonText="TAKE OUR SELF EVALUATION"
        destination="internal"
        contained
      />
    </div>
  )
}

export default CandidateEval
