exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-empower-js": () => import("./../../../src/pages/empower.js" /* webpackChunkName: "component---src-pages-empower-js" */),
  "component---src-pages-empower-terms-conditions-js": () => import("./../../../src/pages/empower-terms-conditions.js" /* webpackChunkName: "component---src-pages-empower-terms-conditions-js" */),
  "component---src-pages-qa-js": () => import("./../../../src/pages/qa.js" /* webpackChunkName: "component---src-pages-qa-js" */),
  "component---src-pages-rise-js": () => import("./../../../src/pages/RISE.js" /* webpackChunkName: "component---src-pages-rise-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-the-fan-method-js": () => import("./../../../src/pages/the-fan-method.js" /* webpackChunkName: "component---src-pages-the-fan-method-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-awards-media-js": () => import("./../../../src/templates/awards-media.js" /* webpackChunkName: "component---src-templates-awards-media-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-blogs-query-js": () => import("./../../../src/templates/blogs-query.js" /* webpackChunkName: "component---src-templates-blogs-query-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-ebook-js": () => import("./../../../src/templates/ebook.js" /* webpackChunkName: "component---src-templates-ebook-js" */),
  "component---src-templates-galleries-js": () => import("./../../../src/templates/galleries.js" /* webpackChunkName: "component---src-templates-galleries-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-self-evalutaion-js": () => import("./../../../src/templates/self-evalutaion.js" /* webpackChunkName: "component---src-templates-self-evalutaion-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/singleDoctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-gallery-js": () => import("./../../../src/templates/single-gallery.js" /* webpackChunkName: "component---src-templates-single-gallery-js" */),
  "component---src-templates-single-procedure-js": () => import("./../../../src/templates/singleProcedure.js" /* webpackChunkName: "component---src-templates-single-procedure-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

