import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Text, Image } from "../components/Core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"

const GalleryQuery = ({ data, pageContext }) => {
  const { metaTitle, metaDescription, heading, blurb, groups } =
    data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  return (
    <Layout navSpacer language={language} className="gallery-query">
      <SEO title={metaTitle} description={metaDescription} lang={language} />

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column gallery-query__heading-container">
            <Text
              as="h1"
              text="We have helped thousands of people look and feel younger."
            />
            <Text
              as="h2"
              text="See the beautiful and natural results Dr. Fan has achieved for our patients."
            />
          </div>
        </Columns>
      </Section>

      <Section zeroBottom className="gallery-query__mobile-spacer">
        {groups.map((item, index) => (
          <ProcedureCard key={index} group={item} index={index} />
        ))}
      </Section>
    </Layout>
  )
}

const ProcedureCard = ({ group }) => {
  return (
    <div id={group.id} className="gallery-query__group-container">
      <div className="gallery-query__image-container">
        <Image
          publicId={group.categories[0].imageDesktop}
          wrapperClassName="gallery-query__image"
        />
      </div>
      <div className="gallery-query__content-container">
        <div className="gallery-query__primary-view">
          <Text as="h2" text={group.label} />
        </div>
        <div className="gallery-query__secondary-view">
          {group.categories[0].links?.map(link => {
            return (
              <Link
                title={`Learn more about ${link.label}`}
                className="gallery-query__link"
                key={link.href}
                to={"/" + link.href}>
                {link.label}
                <div className="gallery-query__right-arrow-btn">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="gallery-query__icon"
                  />
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const galleryQueryPageQuery = graphql`
  query GalleryQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        metaDescription
        metaTitle
        groups {
          label
          id
          categories {
            imageDesktop
            imageMobile
            label
            links {
              href
              label
            }
          }
        }
      }
    }
  }
`

export default GalleryQuery
