import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"
import Dialog from "../components/Modals/Dialog"
import GalleryCase from "../components/Cards/GalleryCase"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown"
import { faSlidersSimple } from "@fortawesome/pro-regular-svg-icons"
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import AccordionBase from "../components/accordion/AccordionBase"

const SingleGallery = ({ data, pageContext, location }) => {
  const [activeCaseNumber, setActiveCaseNumber] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const { metaTitle, metaDescription, heading, cases } =
    data.allGalleriesJson.nodes[0]

  const language = pageContext.language
  // const cases = data.cases.nodes
  const queryPage = `/${data.queryPage.nodes[0].title}`
  const activeCase = cases.find(c => c.caseNumber === activeCaseNumber)

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
    location.href
  )}`

  const [appliedFilters, setAppliedFilters] = useState({})
  const [filteredCases, setFilteredCases] = useState([...cases])
  const [isFiltersOpen, setIsFiltersOpen] = useState(false) // For mobile view

  let filters = [
    {
      label: "INITIAL CUP SIZE",
      name: "beforeSize",
      options: []
    },
    {
      label: "ENHANCED CUP SIZE",
      name: "afterSize",
      options: []
    },
    {
      label: "IMPLANT TYPE",
      name: "implantType",
      options: []
    },
    {
      label: "IMPLANT SHAPE",
      name: "implantShape",
      options: []
    }
  ]

  // creating options list for different types of filters from given cases
  cases.forEach(item => {
    if (item.breast) {
      filters = filters.map(f => {
        if (item.breast[f.name]) {
          return {
            ...f,
            options: new Array(...new Set([...f.options, item.breast[f.name]]))
          }
        }
        return f
      })
    }
  })

  const onSelectedFilter = (filterType, filterOption) => {
    // updating the selected filters
    let updatedFilters = { ...appliedFilters }
    if (filterType in updatedFilters) {
      if (updatedFilters[filterType].has(filterOption)) {
        updatedFilters[filterType].delete(filterOption)
        if (updatedFilters[filterType].size === 0) {
          delete updatedFilters[filterType]
        }
      } else {
        updatedFilters[filterType].add(filterOption)
      }
    } else {
      updatedFilters[filterType] = new Set([filterOption])
    }

    // updating the cases to be shown with selected filters
    let updatedFilteredCases = [...cases]
    if (Object.keys(updatedFilters).length > 0) {
      updatedFilteredCases = updatedFilteredCases.filter(item => {
        let showCase = false
        for (let key in updatedFilters) {
          if (updatedFilters[key].has(item.breast[key]) || !item.breast[key]) {
            showCase = true
          }
        }
        return showCase
      })
    }

    setAppliedFilters(updatedFilters)
    setFilteredCases(updatedFilteredCases)
  }

  return (
    <Layout navSpacer language={language} className="single-gallery">
      <SEO title={metaTitle} description={metaDescription} lang={language} />

      <Section smallSpacing>
        <Columns sideColumnsSize={2}>
          <Button buttonText="Back to Gallery Page" secondary goBack />
        </Columns>
      </Section>

      <Section>
        <Columns>
          <div className="column single-gallery__hero">
            <Text as="h1" text={heading} />
            <h2 className="px-0">
              REAL PATIENTS. <br className="is-hidden-desktop" />
              REAL RESULTS.
            </h2>
          </div>
        </Columns>
      </Section>

      {/**Filter just for breast augmentation */}
      {(location?.href?.includes("/breast-augmentation/") && (
        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column filters__desktop-view-container">
              {filters.map((f, index) => (
                <FilterAccordion
                  key={index}
                  heading={f.label}
                  filter={f}
                  appliedFilters={appliedFilters}
                  onSelectedFilter={onSelectedFilter}
                />
              ))}
            </div>
            <div className="filters__mobile-view-container">
              <div
                className="filters__heading-section"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
                <div className="filters__heading">
                  <div className="filters__title">
                    <FontAwesomeIcon icon={faSlidersSimple} />
                    <Text text={"FILTER BEFORE & AFTERS"} />
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                      transition: "all ease-in-out .3s",
                      transform: !isFiltersOpen ? "rotate(180deg)" : "",
                      color: "#B7B7B7",
                      width: "36px",
                      height: "38px"
                    }}
                  />
                </div>
                <div className="filters__divider">
                  <hr />
                </div>
              </div>
              <div>
                {isFiltersOpen &&
                  filters.map((f, index) => {
                    return (
                      <FilterAccordion
                        key={index}
                        heading={f.label}
                        filter={f}
                        appliedFilters={appliedFilters}
                        onSelectedFilter={onSelectedFilter}
                      />
                    )
                  })}
              </div>
            </div>
          </Columns>
          <div className="filters__desktop-divider">
            <hr />
          </div>
        </Section>
      )) ||
        ""}
      <Section zeroTop>
        <Columns sideColumnsSize={1}>
          <div className="column">
            <div className="sg__cases">
              {filteredCases.map(c => (
                <button
                  onClick={() => {
                    setActiveCaseNumber(c.caseNumber)
                    setIsOpen(true)
                  }}
                  aria-label="open dialog"
                  className="reset sg__case relative"
                  key={c.caseNumber}>
                  <div className="sg__pill before">BEFORE</div>
                  <div className="sg__pill after">AFTER</div>
                  <img
                    className="block"
                    loading="lazy"
                    src={c.images.length >= 2 ? c.images[1] : c.images[0]}
                    alt={`before and after procedure comparison`}
                  />
                  <div className="sg__button">
                    <span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    MORE DETAILS
                  </div>
                </button>
              ))}
            </div>
          </div>
        </Columns>
      </Section>

      {activeCase && (
        <Dialog
          id="modal-top"
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
            setActiveCaseNumber(null)
          }}
          className="single-gallery__dialog">
          <GalleryCase
            procedureName={heading}
            socialShareUrl={facebookShareUrl}
            patient={activeCase}
          />
        </Dialog>
      )}
    </Layout>
  )
}

const FilterAccordion = ({
  heading,
  filter,
  appliedFilters,
  onSelectedFilter
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`filters__dropdown-menu ${isOpen ? "open" : "closed"}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="filters__dropdown-menu-btn">
        <Text text={heading} />
        <FontAwesomeIcon
          style={{
            transition: "all ease-in-out .3s",
            transform: isOpen ? "rotate(180deg)" : "",
            width: "2em",
            height: "1.5em"
          }}
          icon={faChevronDown}
        />
      </button>
      <AccordionBase isOpen={isOpen}>
        <div className="filters__accordion-base">
          {filter.options.map((option, index) => (
            <div
              key={index}
              className={
                appliedFilters[filter.name] &&
                appliedFilters[filter.name].has(option)
                  ? "filters__option-label-focussed"
                  : "filters__option-label"
              }
              onClick={() => onSelectedFilter(filter.name, option)}>
              {option}
            </div>
          ))}
        </div>
      </AccordionBase>
      <div className="filters__divider">
        <hr />
      </div>
    </div>
  )
}

export const SingleGalleryPageQuery = graphql`
  query SingleGallery($title: String!) {
    allGalleriesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        gender
        cases {
          caseNumber
          doctorNotes
          images
          overview
          patientComplaint
          patient {
            age
            gender
            ethnicity
            height
            name
            quote
            weight
          }
          breast {
            implantType
            implantSize
            implantShape
            afterSize
            beforeSize
          }
        }
      }
    }
    cases: allCasesJson(filter: { procedures: { in: [$title] } }) {
      nodes {
        caseNumber
        doctorNotes
        images
        overview
        patient {
          age
          gender
          ethnicity
          height
          name
          quote
          weight
        }
        procedures
        breast {
          implantType
          implantSize
          implantShape
          afterSize
          beforeSize
        }
      }
    }
    queryPage: allUniquePagesJson(
      filter: { template: { eq: "galleries" } }
      limit: 1
    ) {
      nodes {
        title
      }
    }
  }
`

export default SingleGallery
