import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"

import TheaterVideo from "@components/Theater/TheaterVideo"
import SizzleVideo from "../../components/reactPlayerTest"
import "../../components/animations/scroll.scss"

function Sizzle({ playing, children, youtube, language }) {
  return (
    <div className="home-sizzle-wrapper">
      <SizzleVideo
        youtube={youtube}
        language={language}
        playing={playing}
        id="test-player-1"
      />
      {youtube && youtube !== "?" && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          overVideo
          asWrapper
          wrapperClassName="static-heading__theater-btn"
          language={language}>
          <FontAwesomeIcon icon={faPlayCircle} />
        </TheaterVideo>
      )}
      {children}
    </div>
  )
}

export default Sizzle
