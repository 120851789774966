import React, { useState, useRef } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"

import "./Accordion.scss"

const Accordion = ({ heading, children, className, rightAlignHeading }) => {
  const collapsedRef = useRef()

  const [accordionHeight, setAccordionHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    if (isOpen) {
      setAccordionHeight(0)
    } else {
      setAccordionHeight(collapsedRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const contentStyles = {
    maxHeight: accordionHeight
  }

  return (
    <div
      className={`accordion ${className ? className : ""} ${
        isOpen ? "accordion__open" : ""
      }`}>
      <button
        aria-label="toggle accordion"
        className={`reset accordion__heading ${
          rightAlignHeading ? "right" : ""
        }`}
        onClick={toggleAccordion}>
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
        <p className="px-0">{heading}</p>
      </button>

      <div
        className="accordion__content"
        style={contentStyles}
        ref={collapsedRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
