import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Columns, Section } from "../components/Containers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay"
import Seamless from "../components/Seamless"

class iCatPage extends React.Component {
  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <Layout pageTitle="i-cat-page" language={this.props.pageContext.language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={this.props.location.pathname}
          lang={this.props.pageContext.language}
        />

        <Section zeroTop className="i-cat-page__heading-container relative">
          <Columns sideColumnsSize={1} className="i-cat-page__columns">
            <div className="column">
              <Text as="h1" text={post.heading} />
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                playing
                language={this.props.pageContext.language}
                hasWatchVideo={false}>
                <div className="i-cat-page__thumbnail">
                  <div className="i-cat-page__image-container">
                    <Image
                      publicId={post.imageId}
                      wrapperClassName="i-cat-page__image"
                    />
                  </div>
                  <div className="i-cat-page__button-container">
                    <div className="sizzle-button-under button-video icon-only">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                </div>
              </TheaterVideo>
            </div>
          </Columns>
        </Section>

        <Section>
          <Columns sideColumnsSize={1}>
            <div className="column i-cat-page__about">
              <div className="i-cat-page__detail">
                <Text text={post.bodySections[0].string} />
                <Button
                  buttonText="LEARN MORE ABOUT CRISALIX"
                  href="https://www.crisalix.com/en"
                  appearance="contained"
                  className="i-cat-page__learn-more-button"
                />
              </div>
              <div className="i-cat-page__form">
                <Text text="Request your 3d simulation." />
                <Seamless
                  src={
                    "https://secureform.seamlessdocs.com/f/5ade05f14b4afcb4c2d13b1799865b92?embedded=true"
                  }
                />
              </div>
            </div>
          </Columns>
        </Section>
        <Section zeroTop zeroBottom>
          <Columns
            sideColumnsSize={1}
            className="i-cat-page__cta-container is-vcentered is-desktop">
            <div className="column i-cat-page__cta-image">
              <Image publicId="77P/DEV/boost-your-confidence" />
            </div>
            <div className="column is-2"></div>
            <div className="column">
              <Text as="h2" text={"Ready to Start?"} />
              <Text
                as="p"
                text={
                  "Call us today for your complimentary consultation or schedule a complimentary consultation online and see Crisalix in action for yourself. Our expert plastic surgeons are ready to help you plan for your journey for a new you."
                }
              />
              <div className="i-cat-page__button-group">
                <Button
                  buttonText="CONTACT US"
                  href="/contact-us/"
                  appearance="contained"
                />
                <Button
                  buttonText="BOOK A VIRTUAL CONSULTATION"
                  href="/contact-us/"
                  appearance="contained"
                />
              </div>
            </div>
          </Columns>
        </Section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        youtube
        imageId
        bodySections {
          type
          string
          code
          # youtube
          # image
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
  }
`

export default iCatPage
