import React from "react"
import { Link } from "gatsby"
import "../css/pages/_empower.scss"
import { Image } from "../components/Core"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/_empower.scss"

const empower = () => {
  return (
    <Layout whiteNav className="emp" language="en">
      <SEO title="EMPOWER" description="EMPOWER" />

      <div className="emp__hero">
        <h1>
          Breaking
          <br /> The Bullying
          <br />
          Cycle
        </h1>

        <div className="hero-logo">
          <Image publicId="77P/DEV/empower-logo" />
        </div>
      </div>

      <div className="emp__section">
        <div className="emp__container">
          <div className="mobile-grid mb-2">
            <h2 className="px-0">
              EMPOWER
              <br />
              Others To Be Kind
            </h2>
            <div className="mobile-logo">
              <Image publicId="77P/DEV/empower-logo" />
            </div>
          </div>
          <p>
            When you’re kind to others, you do good and you feel good. At 77
            Plastic Surgery, we are proud to announce our 2024 EMPOWER Human
            Kindness initiative.
          </p>
          <p>
            Students everywhere are making a difference—and we want to hear
            about it! We invite students (or those who know a student) to share
            their stories of how they EMPOWER Human Kindness by serving others
            at school or throughout our community and beyond.
          </p>
          <p>
            After the application period, we will select one deserving student
            recipient to receive a $777 scholarship and be our EMPOWER Human
            Kindness Ambassador!
          </p>
        </div>
      </div>

      <div className="emp__section">
        <div className="emp__container">
          <div style={{ border: "1px solid #003473" }}></div>
        </div>
      </div>

      <div className="emp__section">
        <div className="emp__container">
          <h2>
            Teach it. Show it. <br />
            Get Recognized.
          </h2>

          <p className="bold">HERE’S HOW IT WORKS:</p>
          <p>
            &#8226; Students under the age of 18 (Grade 1 through College) can
            share their stories from now through November 20th, 2024, with a
            video or written submission.
          </p>
          <p>
            &#8226; Students, parents, teachers, coaches, and other community
            members can nominate a student by submitting a story or video.
          </p>
          <p>
            &#8226; After the application period, we will announce our EMPOWER
            Human Kindness Ambassador.
          </p>

          <p className="mt-2">
            <b>
              Thank you for your interest in the EMPOWER Kindness program. The
              application period is now closed. Applicants will be notified once
              the selection process has been completed.
            </b>
          </p>
        </div>
      </div>

      {/* <div className="emp__form">
        <h2 className="has-text-centered mb-2" style={{ fontSize: 24 }}>
          SUBMIT YOUR APPLICATION
        </h2>
        <iframe
          class="seamlessdocsEmbededIframe"
          src="https://secureform.seamlessdocs.com/f/f1e19789ba3db0ba9003844cff9d7f6f?embedded=true"
          width="100%"
          height="900px"
          frameborder="0"
          allowtransparency="true"
          sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox"></iframe>
      </div> */}

      <div className="emp__section">
        <div className="emp__container">
          <p className="has-text-centered" style={{ fontSize: "16px" }}>
            <i>
              <Link
                to="/empower-terms-conditions/"
                title="Read the Terms & Conditions">
                Terms & Conditions
              </Link>
            </i>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default empower
