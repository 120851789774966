import React from "react"
import classNames from "classnames"
import { Text } from "../Core"
import { Columns } from "../Containers"

const Heading = ({
  heading,
  blurb,
  boldBlurb = false,
  sideColumnsSize = 2,
  className,
  htmlBefore
}) => {
  if (!heading) return <></>

  const mainClasses = classNames(className, "page-heading")
  const blurbClasses = classNames({ bold: boldBlurb })

  return (
    <>
      {" "}
      <div className={mainClasses}>
        <Columns sideColumnsSize={sideColumnsSize}>
          <div className="column">
            {htmlBefore && htmlBefore}
            <Text as="h1" text={heading} />
            {blurb && (
              <Text className={blurbClasses} useStringReplace text={blurb} />
            )}
          </div>
        </Columns>
      </div>
    </>
  )
}

export default Heading
