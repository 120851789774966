import React, { useState, useRef, useEffect } from "react"
import CountUp from "react-countup"
import useIntersectionObserver from "../hooks/useIntersectionObserver"

const CountAnimate = ({ start = 0, end = 0, children }) => {
  const [mounted, setMounted] = useState(false)
  const ref = useRef()
  const isIntersecting = useIntersectionObserver(ref, {
    threshold: 0.2
  })

  useEffect(() => {
    if (isIntersecting && !mounted) {
      setMounted(true)
    }
  }, [isIntersecting])

  return (
    <div ref={ref}>
      <CountUp
        start={start}
        end={end}
        // delay={0}
        duration={2.75}
        decimal=","
        suffix="+">
        {({ countUpRef, start }) => {
          if (mounted) {
            start()
          }
          return (
            <>
              <span
                style={{ display: "block", textAlign: "center" }}
                ref={countUpRef}
              />
              {children}
            </>
          )
        }}
      </CountUp>
    </div>
  )
}

export default CountAnimate
