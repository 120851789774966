import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import AccordionBase from "./AccordionBase"
import { Text } from "../Core"

const ReasonAccordion = ({ num, heading, blurb }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`about-us__reasons--accordion ${isOpen ? "open" : ""}`}>
      <button onClick={() => setIsOpen(!isOpen)} className="reset">
        <h3 className="px-0">
          {num && (
            <span style={{ marginRight: "10px" }} className="bold">
              {num}
            </span>
          )}
          {heading}
        </h3>
        <FontAwesomeIcon
          style={{
            transition: "all ease-in-out .3s",
            transform: isOpen ? "rotate(180deg)" : ""
          }}
          icon={faChevronDown}
        />
      </button>
      <AccordionBase isOpen={isOpen}>
        <Text
          style={{ paddingTop: "16px" }}
          className="px-0"
          as="p"
          text={blurb}
        />
      </AccordionBase>
    </div>
  )
}

export default ReasonAccordion
