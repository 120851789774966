import React, { useRef } from "react"
import Slider from "react-slick"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"

import { SocialIconLink } from "../SocialReviews"

const GalleryCase = ({ procedureName, patient, socialShareUrl = "" }) => {
  let sliderRef = useRef(null)

  let settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignContent: "center",
          position: "relative",
          bottom: "auto",
          marginTop: "20px"
        }}>
        <button
          className="reset gc__arrow-btn"
          aria-label="previous slide"
          onClick={() => sliderRef.slickPrev()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <ul
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
            margin: "0px"
          }}>
          {" "}
          {dots}{" "}
        </ul>
        <button
          className="reset gc__arrow-btn"
          aria-label="next slide"
          onClick={() => sliderRef.slickNext()}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    )
  }

  const characterCountClass = str => {
    const length = str?.length
    let className = "lg"
    if (length > 450) {
      className = "sm"
    }
    return className
  }

  const getProcedureName = name => {
    let wordsToRemove = "photo gallery"
    if (name.toLowerCase().includes(wordsToRemove))
      return name.substring(0, name.toLowerCase().indexOf(wordsToRemove))
    return name
  }

  return (
    <div className="gc">
      <div className="gc__carousel">
        <Slider
          ref={slider => {
            sliderRef = slider
          }}
          {...settings}>
          {patient.images
            .filter(image => !image.includes("query"))
            .map((img, idx) => (
              <div className="relative" key={img}>
                <div className="sg__pill gc__case before">BEFORE</div>
                <div className="sg__pill gc__case after">AFTER</div>
                <img
                  className="block"
                  src={img}
                  alt={`before and after image number ${idx + 1}`}
                />
              </div>
            ))}
        </Slider>
      </div>

      <div className="gc__stats">
        <div className="gc__stats-heading px-40--mobile">
          <h4 className="bold--600">{getProcedureName(procedureName)}</h4>{" "}
          <p>{patient.caseNumber}</p>
        </div>
        <div className="gc__stats-info">
          <p>
            <span className="bold--600">{patient.patient.name}</span>
          </p>
          <p>
            <span className="bold--600">Weight</span>:{" "}
            {patient.patient.weight || "N/A"}
          </p>
          <p>
            <span className="bold--600">Age</span>: {patient.patient.age}
          </p>
          <p>
            <span className="bold--600">Gender</span>: {patient.patient.gender}
          </p>
          <p>
            <span className="bold--600">Height</span>: {patient.patient.height}
          </p>
          <p>
            <span className="bold--600">Ethnicity</span>:{" "}
            {patient.patient.ethnicity}
          </p>
        </div>

        <div className="gc__patient-issue">
          <span className="bold--600">Patient's Issue:</span>
          <span className={characterCountClass(patient.patientComplaint)}>
            {patient.patientComplaint}
          </span>
        </div>
      </div>

      <div className="gc__doctor-quote-mobile">
        <span className="bold--600">Doctor's Solution:</span>
        <span className={characterCountClass(patient.doctorNotes)}>
          {patient.doctorNotes}
        </span>
      </div>

      <div className="gc__quote">
        <svg
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="39"
          viewBox="0 0 67 59"
          fill="none">
          <path
            d="M40.8073 5.27917L15.1575 52.5897L1.44785 48.041L26.8416 1.16643C31.126 2.33983 36.025 3.78025 40.8073 5.27917ZM65.5429 10.4766L39.8931 57.7871L26.1835 53.2384L51.5772 6.36384C55.8617 7.53725 60.7606 8.97766 65.5429 10.4766Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
        <h5 className={characterCountClass(patient.patient.quote)}>
          {patient.patient.quote}
        </h5>
        <p>– {patient.patient.name}</p>
      </div>

      <div className="gc__doctor-quote-with-social">
        <div className="gc__doctor-quote-desktop">
          <span className="bold--600">Doctor's Solution:</span>
          <span className={characterCountClass(patient.doctorNotes)}>
            {patient.doctorNotes}
          </span>
        </div>
        <div className="gc__social-desktop">
          <p className="bold--600">Share this experience!</p>
          <SocialIconLink
            iconColor="black"
            platform="Facebook"
            link={socialShareUrl}
          />
        </div>
      </div>

      <div className="gc__social-mobile">
        <p className="bold--600">Share This Experience!</p>
        <SocialIconLink platform="Facebook" link={socialShareUrl} />
      </div>
    </div>
  )
}

export default GalleryCase
