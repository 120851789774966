import React from "react"
import { Text } from "../../components/Core"
import { Columns } from "../../components/Containers"

const StaticHero = ({ heading, subheading, bgImage, language }) => {
  const imgStyles = {}

  if (bgImage) {
    imgStyles.backgroundImage = `url(https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/${bgImage})`
    imgStyles.backgroundPosition = "center top"
    imgStyles.backgroundSize = "cover"
    imgStyles.backgroundRepeat = "no-repeat"
    imgStyles.backgroundColor = "transparent"
  }
  return (
    <div className="homepage-hero" style={imgStyles}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <Text as="h1" className="h1-style" text={heading} />
          <Text
            useStringReplace
            as="p"
            className="hero-heading"
            text={subheading}
          />
        </div>
      </Columns>
    </div>
  )
}

export default StaticHero
